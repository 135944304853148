import { motion, AnimatePresence } from "framer-motion";
import { faQuestionCircle, faChevronDown, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const fadeInUp = {
    initial: { opacity: 0, y: 30 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: "easeOut" }
  };
  
  const staggerContainer = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  const faqs = [
    {
      question: "When can I try your internal PPC tool myself?",
      answer: "We currently use it behind the scenes for our clients, and we'll soon make it available for self-service. Keep an eye out for the announcement—you'll love having this level of insight at your fingertips."
    },
    {
      question: "How fast will I see improvements?",
      answer: "It varies, but many clients notice better results—like improved CTR or a dip in ACoS—within the first month or so. Over time, as we refine and optimize, those gains tend to get even better."
    },
    {
      question: "Can you handle all types of Amazon ads?",
      answer: "Absolutely. We work with Sponsored Products, Sponsored Brands, Sponsored Display, and DSP. We design a mix that reaches your customers wherever they are in their journey."
    },
    {
      question: "Do I have to sign a long-term contract?",
      answer: "Nope. We think our work should speak for itself, so we keep things flexible and aim to earn your trust month after month."
    },
    {
      question: "How is your pricing structured?",
      answer: "Our pricing is transparent and based on your advertising spend. We offer tiered pricing to ensure you get the best value whether you're a growing brand or an established seller with substantial ad budgets."
    },
    {
      question: "Can you work with my existing campaigns?",
      answer: "Yes! We can work with your existing campaigns or build new ones from scratch. Our team will analyze your current strategy first to determine the best path forward."
    }
  ];

  return (
    <section id="faq" className="relative py-32 bg-slate-50 overflow-hidden">
      {/* Enhanced Background Elements */}
      <div className="absolute inset-0">
        {/* Dynamic animated circles */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-green-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-green-100/40 rounded-full 
             transform rotate-12
             animate-[pulse_15s_ease-in-out_infinite]"></div>

        {/* Small floating circles */}
        <div className="absolute top-1/4 left-1/4 w-16 h-16 
             bg-green-50 rounded-full opacity-70
             animate-[float_8s_ease-in-out_infinite]"></div>
             
        <div className="absolute bottom-1/3 right-1/3 w-8 h-8 
             bg-green-100 rounded-full opacity-60
             animate-[float_6s_ease-in-out_infinite_1s]"></div>

        {/* Dot pattern */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Enhanced squiggly decorative lines */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-green-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
        
        <svg className="absolute bottom-20 right-20 w-32 h-32 text-green-100/40" viewBox="0 0 100 100">
          <path d="M10,30 Q30,10 50,30 T90,30" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_15s_linear_infinite_reverse]"/>
        </svg>
      </div>

      <div className="max-w-7xl mx-auto px-6 relative z-10">
        <motion.div
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="space-y-16"
        >
          {/* Enhanced Heading Section */}
          <motion.div {...fadeInUp} className="text-center space-y-8">
            <div className="inline-block mb-4">
              <motion.span 
                className="inline-flex items-center px-8 py-3 rounded-full 
                        text-base font-medium tracking-wide text-slate-800
                        bg-white border-2 border-slate-200
                        shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                        hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                        transform hover:-translate-y-0.5 transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} 
                  className="mr-2 text-green-600" />
                <motion.span
                  animate={{ y: [0, -3, 0] }}
                  transition={{ 
                    repeat: Infinity, 
                    duration: 1.5,
                    repeatType: "reverse" 
                  }}
                >
                  Common Questions
                </motion.span>
              </motion.span>
            </div>

            <h2 className="text-5xl md:text-6xl font-bold tracking-tight text-slate-900 relative">
              Frequently Asked <span className="text-green-600">Questions</span>
              <motion.svg 
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 1.5, ease: "easeInOut" }}
                className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-64 md:w-80" 
                height="10" 
                viewBox="0 0 300 10"
              >
                <path 
                  d="M0,5 Q75,0 150,5 T300,5" 
                  fill="none" 
                  stroke="#059669" 
                  strokeWidth="2"
                  className="animate-[dash_3s_linear_infinite]"
                />
              </motion.svg>
            </h2>
            
            <p className="text-lg md:text-xl text-slate-700 max-w-3xl mx-auto 
                          leading-relaxed font-normal tracking-wide">
              Have questions about how we can help optimize your Amazon PPC campaigns?
              Here are some of the most common questions we receive.
            </p>
          </motion.div>

          {/* Enhanced FAQ Items */}
          <motion.div 
            variants={staggerContainer}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className="grid md:grid-cols-2 gap-6 max-w-5xl mx-auto"
          >
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                variants={{
                  initial: { opacity: 0, y: 30 },
                  animate: { 
                    opacity: 1, 
                    y: 0,
                    transition: { duration: 0.5 }
                  }
                }}
                className="group relative"
              >
                <button
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                  className="w-full text-left"
                  aria-expanded={openIndex === index}
                  aria-controls={`faq-answer-${index}`}
                >
                  <div className={`relative bg-white p-8 rounded-3xl
                              border-2 ${openIndex === index ? 'border-green-200' : 'border-slate-200'}
                              ${openIndex === index ? 'shadow-[8px_8px_0px_0px_rgba(0,0,0,0.08)]' : 'shadow-[6px_6px_0px_0px_rgba(0,0,0,0.05)]'}
                              hover:border-green-200
                              hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,0.08)]
                              transform hover:-translate-y-1
                              transition-all duration-300`}>
                    
                    {/* Decorative circle */}
                    <div className="absolute top-0 right-0 w-32 h-32 
                                border border-dashed border-green-100 
                                rounded-full -translate-y-16 translate-x-16 
                                group-hover:scale-150 transition-transform duration-500"></div>

                    <div className="relative">
                      <div className="flex justify-between items-center gap-4">
                        <h3 className="text-lg md:text-xl font-bold text-slate-900 
                                   tracking-wide group-hover:text-green-600
                                   transition-colors duration-300">
                          {faq.question}
                        </h3>
                        {/* Updated toggle button */}
                        <div className={`flex-shrink-0 w-10 h-10 flex items-center justify-center
                                     ${openIndex === index ? 'bg-green-100' : 'bg-green-50'} rounded-xl
                                     transform ${openIndex === index ? 'rotate-6' : 'rotate-3'} 
                                     group-hover:rotate-6
                                     transition-all duration-300
                                     border-2 ${openIndex === index ? 'border-green-300' : 'border-green-200'}`}>
                          <FontAwesomeIcon 
                            icon={openIndex === index ? faMinus : faPlus}
                            className={`w-4 h-4 text-green-600 transition-all duration-300
                                    transform -rotate-3 group-hover:-rotate-6`}
                          />
                        </div>
                      </div>
                      
                      {/* Answer with smooth animation */}
                      <AnimatePresence>
                        {openIndex === index && (
                          <motion.div
                            id={`faq-answer-${index}`}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                            className="overflow-hidden"
                          >
                            <div className="mt-6 pl-6 border-l-2 border-green-200">
                              <p className="text-base md:text-lg text-slate-700 
                                         leading-relaxed tracking-wide font-normal">
                                {faq.answer}
                              </p>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </button>
              </motion.div>
            ))}
          </motion.div>
          
          {/* Additional Help CTA */}
          <motion.div 
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="mt-16 text-center"
          >
            <div className="relative inline-block">
              <div className="absolute inset-0 bg-green-100/50 blur-lg rounded-xl"></div>
              <div className="relative bg-white p-8 rounded-2xl border-2 border-green-200
                            shadow-[6px_6px_0px_0px_rgba(5,150,105,0.2)]
                            hover:shadow-[8px_8px_0px_0px_rgba(5,150,105,0.25)]
                            transform hover:-translate-y-1 transition-all duration-300
                            max-w-2xl mx-auto">
                <h3 className="text-xl font-semibold text-slate-900 mb-3">
                  Still have questions?
                </h3>
                <p className="text-slate-700 mb-6">
                  Our team is ready to help with any other questions you might have about our Amazon PPC services.
                </p>
                <a href="mailto:contact@trulyppc.com" 
                   className="inline-flex items-center px-8 py-3 rounded-xl 
                          bg-green-600 text-white font-medium
                          shadow-[3px_3px_0px_0px_rgba(5,150,105,0.3)]
                          hover:shadow-[5px_5px_0px_0px_rgba(5,150,105,0.35)]
                          transform hover:-translate-y-1 transition-all duration-300">
                  Contact Us
                  <svg className="ml-2 w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </a>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}

export default FAQ;