import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faComments, faHandshake, faRocket } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../components/landing/Navbar';
import Footer from '../components/landing/Footer';
import { useEffect } from 'react';

function ChatPage() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: "easeOut" }
  };

  const benefits = [
    {
      icon: faComments,
      title: "Personalized Strategy",
      description: "Get tailored advice specific to your Amazon business needs"
    },
    {
      icon: faHandshake,
      title: "Expert Consultation",
      description: "Connect with our PPC specialists who understand Amazon inside out"
    },
    {
      icon: faCalendarCheck,
      title: "Flexible Scheduling",
      description: "Choose a time that works best for your schedule"
    },
    {
      icon: faRocket,
      title: "Growth Planning",
      description: "Map out your path to scaling your Amazon business"
    }
  ];

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    
    // Load Calendly widget script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen bg-slate-50">
      <Navbar />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        {/* Background Elements */}
        <div className="absolute inset-0">
          <div className="absolute top-20 right-10 w-[300px] h-[300px] 
               border-[4px] border-dashed border-green-100/30 rounded-full 
               animate-[spin_30s_linear_infinite]"></div>
          <div className="absolute -bottom-10 -left-10 w-[200px] h-[200px] 
               border-[3px] border-green-100/40 rounded-full"></div>
        </div>

        <div className="max-w-7xl mx-auto px-6 pt-32 pb-20 relative z-10">
          <motion.div
            initial="initial"
            animate="animate"
            className="text-center space-y-6"
          >
            <motion.div {...fadeInUp}>
              <span className="inline-block px-4 py-2 rounded-full text-sm font-medium 
                           bg-green-100 text-green-600 mb-4">
                Free Strategy Call
              </span>
              <h1 className="text-5xl md:text-6xl font-bold text-slate-900 mb-6">
                Let's Discuss Your <br />
                <span className="text-green-600">Amazon PPC Goals</span>
              </h1>
              <p className="text-xl text-slate-600 max-w-2xl mx-auto">
                Schedule a free 30-minute consultation with our PPC experts to discuss 
                your goals and discover how we can help scale your Amazon business.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Benefits Grid */}
      <div className="max-w-7xl mx-auto px-6 pb-20">
        <motion.div 
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
        >
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              {...fadeInUp}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center mb-4">
                <FontAwesomeIcon icon={benefit.icon} className="text-green-600 text-xl" />
              </div>
              <h3 className="text-lg font-semibold text-slate-900 mb-2">{benefit.title}</h3>
              <p className="text-slate-600">{benefit.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Calendar Section */}
      <div className="max-w-6xl mx-auto px-6 pb-32">
        <motion.div
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="bg-white rounded-2xl shadow-xl overflow-hidden"
        >
          <div className="p-8 bg-gradient-to-r from-green-500 to-green-600">
            <motion.h2 {...fadeInUp} className="text-3xl font-bold text-white text-center">
              Schedule Your Free Strategy Call
            </motion.h2>
          </div>

          <motion.div {...fadeInUp} className="p-8">
            {/* Calendar Widget - Full width */}
            <div 
              className="calendly-inline-widget rounded-xl overflow-hidden"
              data-url="https://calendly.com/ashutosh-trulyppc/30min?hide_gdpr_banner=1&background_color=ffffff&text_color=1e293b&primary_color=059669"
              style={{ 
                width: '100%',
                height: '700px',
                border: '1px solid #e2e8f0',
                borderRadius: '12px'
              }}
            ></div>

            {/* Trust Badges */}
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-center space-x-3 p-4 bg-white border border-slate-200 rounded-lg">
                <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center">
                  <FontAwesomeIcon icon={faCalendarCheck} className="text-green-600" />
                </div>
                <div>
                  <h4 className="font-medium text-slate-900">Instant Confirmation</h4>
                  <p className="text-sm text-slate-600">Get immediate access to our calendar</p>
                </div>
              </div>

              <div className="flex items-center space-x-3 p-4 bg-white border border-slate-200 rounded-lg">
                <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                  <FontAwesomeIcon icon={faHandshake} className="text-blue-600" />
                </div>
                <div>
                  <h4 className="font-medium text-slate-900">No Obligation</h4>
                  <p className="text-sm text-slate-600">Free consultation with no strings attached</p>
                </div>
              </div>
            </div>

            <p className="text-center text-sm text-slate-500 mt-6 pt-4 border-t">
              By scheduling a call, you agree to our{' '}
              <a href="/terms" className="text-green-600 hover:text-green-700">terms of service</a>
              {' '}and{' '}
              <a href="/privacy" className="text-green-600 hover:text-green-700">privacy policy</a>.
            </p>
          </motion.div>
        </motion.div>
      </div>

      <Footer />
    </div>
  );
}

export default ChatPage;