import { useState, useEffect } from 'react';

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      scrolled 
        ? 'bg-white/80 backdrop-blur-xl shadow-sm' 
        : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex items-center justify-between h-20">
          {/* Logo with Image */}
          <a href="/" className="group flex items-center space-x-2 relative">
            <div className="absolute inset-0 bg-teal-500/10 blur-xl rounded-full 
                          opacity-0 group-hover:opacity-100 transition-all duration-500"></div>
            <img 
              src="/TrulyPPC_Logo_Green.png" 
              alt="TrulyPPC Logo"
              className="h-10 w-auto relative transition-all duration-300" 
            />
          </a>

          {/* Solution Types */}
          <div className="flex items-center gap-6">
            {/* Managed Solution */}
            <div className={`hidden sm:block text-sm font-medium transition-colors duration-300 ${
              scrolled ? 'text-slate-700' : 'text-slate-700'
            }`}>
              Managed Solution
              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs 
                             font-medium bg-green-50 text-green-600
                             border border-green-200">
                Active
              </span>
            </div>

            {/* Self-Service - Coming Soon */}
            <div className={`hidden sm:block text-sm font-medium transition-colors duration-300 ${
              scrolled ? 'text-slate-600' : 'text-slate-600'
            }`}>
              Self-Service
              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs 
                             font-medium bg-slate-50 text-slate-600
                             border border-slate-200">
                Coming Soon
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}