import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faLightbulb, faMagnifyingGlass, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../components/landing/Navbar';
import Footer from '../components/landing/Footer';
import { useEffect } from 'react';

function AuditPage() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: "easeOut" }
  };

  const benefits = [
    {
      icon: faChartLine,
      title: "Performance Analysis",
      description: "Deep dive into your campaign metrics, ACOS, and ROI"
    },
    {
      icon: faLightbulb,
      title: "Optimization Tips",
      description: "Actionable recommendations to improve your campaigns"
    },
    {
      icon: faMagnifyingGlass,
      title: "Keyword Analysis",
      description: "Discover untapped keyword opportunities and waste spend"
    },
    {
      icon: faShieldHalved,
      title: "Competitor Insights",
      description: "Understanding your position in the marketplace"
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(new FormData(form)).toString()
    })
    .then(() => {
      // Optional: Add success handling here
      console.log('Form successfully submitted');
      // You could redirect or show a success message
    })
    .catch((error) => {
      // Optional: Add error handling here
      console.error('Form submission error:', error);
    });
  };

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-slate-50">
      <Navbar />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        {/* Background Elements */}
        <div className="absolute inset-0">
          <div className="absolute top-20 right-10 w-[300px] h-[300px] 
               border-[4px] border-dashed border-green-100/30 rounded-full 
               animate-[spin_30s_linear_infinite]"></div>
          <div className="absolute -bottom-10 -left-10 w-[200px] h-[200px] 
               border-[3px] border-green-100/40 rounded-full"></div>
        </div>

        <div className="max-w-7xl mx-auto px-6 pt-32 pb-20 relative z-10">
          <motion.div
            initial="initial"
            animate="animate"
            className="text-center space-y-6"
          >
            <motion.div {...fadeInUp}>
              <span className="inline-block px-4 py-2 rounded-full text-sm font-medium 
                           bg-green-100 text-green-600 mb-4">
                Free PPC Analysis
              </span>
              <h1 className="text-5xl md:text-6xl font-bold text-slate-900 mb-6">
                Unlock Your Amazon PPC <br />
                <span className="text-green-600">Full Potential</span>
              </h1>
              <p className="text-xl text-slate-600 max-w-2xl mx-auto">
                Get a comprehensive analysis of your Amazon PPC campaigns and discover 
                opportunities to reduce wasted spend and increase your ROI.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Benefits Grid */}
      <div className="max-w-7xl mx-auto px-6 pb-20">
        <motion.div 
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
        >
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              {...fadeInUp}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center mb-4">
                <FontAwesomeIcon icon={benefit.icon} className="text-green-600 text-xl" />
              </div>
              <h3 className="text-lg font-semibold text-slate-900 mb-2">{benefit.title}</h3>
              <p className="text-slate-600">{benefit.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Form Section */}
      <div className="max-w-4xl mx-auto px-6 pb-32">
        <motion.div
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="bg-white rounded-2xl shadow-xl overflow-hidden"
        >
          <div className="p-8 bg-gradient-to-r from-green-500 to-green-600">
            <motion.h2 {...fadeInUp} className="text-3xl font-bold text-white text-center">
              Request Your Free Audit
            </motion.h2>
          </div>

          <motion.form 
            {...fadeInUp} 
            className="p-8 space-y-6" 
            onSubmit={handleSubmit}
            name="audit-request"
            data-netlify="true"
            netlify-honeypot="bot-field"
            action="/thank-you"
          >
            <input type="hidden" name="form-name" value="audit-request" />
            <input type="hidden" name="bot-field" />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-slate-700 mb-2">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-slate-700 mb-2">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="website" className="block text-sm font-medium text-slate-700 mb-2">Amazon Store URL</label>
              <input
                type="url"
                id="website"
                className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label htmlFor="monthly_spend" className="block text-sm font-medium text-slate-700 mb-2">Monthly Ad Spend (USD)</label>
              <select
                id="monthly_spend"
                className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                required
              >
                <option value="">Select your monthly spend</option>
                <option value="0-1000">$0 - $1,000</option>
                <option value="1000-5000">$1,000 - $5,000</option>
                <option value="5000-10000">$5,000 - $10,000</option>
                <option value="10000+">$10,000+</option>
              </select>
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-slate-700 mb-2">What are your main PPC challenges?</label>
              <textarea
                id="message"
                rows="4"
                className="w-full px-4 py-3 rounded-lg border border-slate-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-green-600 text-white font-semibold py-4 rounded-xl
                       shadow-[6px_6px_0px_0px_rgba(5,150,105,0.3)]
                       hover:shadow-[8px_8px_0px_0px_rgba(5,150,105,0.35)]
                       transform hover:-translate-y-0.5
                       transition-all duration-300"
            >
              Request Free Audit
            </button>

            <p className="text-center text-sm text-slate-500">
              Your data is secure and will never be shared with third parties.
            </p>
          </motion.form>
        </motion.div>
      </div>

      <Footer />
    </div>
  );
}

export default AuditPage;