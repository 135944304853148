import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faArrowRight, faCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

export default function Footer() {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  
  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email && email.includes('@')) {
      // In a real implementation, you would send this to your API
      setSubscribed(true);
      setEmail('');
      // Reset the subscribed state after 5 seconds
      setTimeout(() => setSubscribed(false), 5000);
    }
  };

  const socialLinks = [
    { icon: faLinkedin, href: "https://www.linkedin.com/company/trulyppc/about/?viewAsMember=true", label: "LinkedIn" },
    { icon: faTwitter, href: "#", label: "Twitter" },
    { icon: faFacebook, href: "#", label: "Facebook" },
    { icon: faEnvelope, href: "mailto:contact@trulyppc.com", label: "Email" },
  ];

  const quickLinks = [
    { name: "Features", href: "#features" },
    { name: "How It Works", href: "#how-it-works" },
    { name: "Pricing", href: "#pricing" },
    { name: "FAQ", href: "#faq" },
  ];

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
    transition: { duration: 0.5 }
  };

  return (
    <footer className="relative bg-slate-50 pt-20 pb-10 overflow-hidden">
      {/* Updated Background Elements */}
      <div className="absolute inset-0">
        {/* Hand-drawn circles */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-green-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-green-100/40 rounded-full 
             transform rotate-12"></div>

        {/* Dot pattern */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Updated squiggly line */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-green-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
      </div>

      <div className="relative max-w-7xl mx-auto px-6">
        {/* Top Section with Grid */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-12 mb-16">
          {/* Company Info - 4 columns */}
          <motion.div {...fadeInUp} className="md:col-span-4 space-y-8">
            {/* Logo */}
            <a href="/" className="group flex items-center space-x-2 relative inline-block">
              <div className="absolute inset-0 bg-green-500/10 blur-xl rounded-full 
                          opacity-0 group-hover:opacity-100 transition-all duration-500"></div>
              <img 
                src="/TrulyPPC_Logo_Green.png" 
                alt="TrulyPPC Logo"
                className="h-12 w-auto relative transition-all duration-300 group-hover:scale-105" 
              />
            </a>
            
            {/* Description */}
            <p className="text-base md:text-lg text-slate-700 max-w-md 
                          font-normal tracking-wide leading-relaxed">
              Revolutionizing Amazon PPC management with AI-driven solutions that deliver measurable results and maximize your advertising ROI.
            </p>

            {/* Amazon Verified Partner Badge */}
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-white rounded-lg shadow-sm border border-slate-200">
                <img 
                  src="/Verifiedpartnerbadge.png"
                  alt="Amazon Ads Verified Partner"
                  className="h-12 w-auto" 
                />
              </div>
              <div className="text-sm font-medium text-slate-700">
                Amazon Ads<br />Verified Partner
              </div>
            </div>

            {/* Updated Social Links */}
            <div className="flex gap-4">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  aria-label={social.label}
                  className="group"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="p-3 rounded-xl bg-white
                              border-2 border-slate-200
                              shadow-[3px_3px_0px_0px_rgba(0,0,0,0.08)]
                              hover:shadow-[5px_5px_0px_0px_rgba(0,0,0,0.1)]
                              transform rotate-2 hover:rotate-4
                              hover:-translate-y-1
                              transition-all duration-300">
                    <FontAwesomeIcon 
                      icon={social.icon} 
                      className="w-5 h-5 text-green-600 transform -rotate-2 
                               group-hover:-rotate-4 transition-transform duration-300" 
                    />
                  </div>
                </a>
              ))}
            </div>
          </motion.div>

          {/* Quick Links - 3 columns */}
          <motion.div {...fadeInUp} transition={{ delay: 0.1 }} className="md:col-span-3 space-y-6">
            <h3 className="text-xl font-semibold tracking-wide text-slate-900">
              Quick Links
            </h3>
            <ul className="space-y-4">
              {quickLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.href} className="group flex items-center text-base text-slate-700 
                                   hover:text-green-600 transition-colors duration-300">
                    <span className="w-1.5 h-1.5 bg-slate-300 rounded-full mr-2 
                                group-hover:bg-green-500 transition-colors duration-300"></span>
                    {link.name}
                    <span className="ml-1 opacity-0 group-hover:opacity-100 
                              transform translate-x-0 group-hover:translate-x-1 
                              transition-all duration-300">→</span>
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>

          {/* Contact - 5 columns */}
          <motion.div {...fadeInUp} transition={{ delay: 0.2 }} className="md:col-span-5 space-y-6">
            <h3 className="text-xl md:text-2xl font-semibold tracking-wide
                           text-slate-900 relative inline-block">
              Stay <span className="text-green-600">Updated</span>
              <svg className="absolute -bottom-2 w-full" height="6" viewBox="0 0 100 6">
                <path d="M0,3 Q25,0 50,3 T100,3" fill="none" stroke="#059669" strokeWidth="2"
                      className="animate-[dash_3s_linear_infinite]"/>
              </svg>
            </h3>
            <p className="text-base md:text-lg text-slate-700 
                          font-normal tracking-wide leading-relaxed">
              Get notified about our self-service launch, new features, and Amazon PPC insights.
            </p>

            {/* Updated Email Input with Animation */}
            <form onSubmit={handleSubscribe} className="relative">
              <div className="flex max-w-md">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="flex-1 px-6 py-4 bg-white 
                         border-2 border-slate-200 rounded-l-xl 
                         text-slate-800 text-base tracking-wide
                         placeholder:text-slate-400 placeholder:font-normal
                         focus:outline-none focus:border-green-300
                         shadow-[4px_4px_0px_0px_rgba(0,0,0,0.05)]
                         transition-all duration-300"
                  required
                />
                <button 
                  type="submit"
                  className="px-6 py-4 bg-green-600
                         text-white rounded-r-xl 
                         shadow-[4px_4px_0px_0px_rgba(5,150,105,0.3)]
                         hover:shadow-[6px_6px_0px_0px_rgba(5,150,105,0.35)]
                         transform hover:-translate-y-0.5
                         transition-all duration-300 group
                         overflow-hidden relative"
                  aria-label="Subscribe"
                >
                  {subscribed ? (
                    <>
                      <span className="flex items-center justify-center">
                        <FontAwesomeIcon icon={faCheck} className="w-5 h-5" />
                        <span className="ml-2">Thanks!</span>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="relative z-10 flex items-center justify-center">
                        <FontAwesomeIcon icon={faPaperPlane} className="w-5 h-5 mr-2" />
                        <span>Subscribe</span>
                      </span>
                      <span className="absolute inset-0 bg-green-700 translate-y-full 
                                 group-hover:translate-y-0 transition-transform duration-300"></span>
                    </>
                  )}
                </button>
              </div>
            </form>

            {/* Contact Information */}
            <div className="pt-4">
              <div className="bg-white p-6 rounded-xl border-2 border-slate-200 
                            shadow-[4px_4px_0px_0px_rgba(0,0,0,0.05)]
                            hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.08)]
                            transform hover:-translate-y-1 transition-all duration-300">
                <h4 className="font-medium text-slate-800 mb-3">Contact Us</h4>
                <p className="text-sm text-slate-700 mb-2">
                  Have questions or need assistance? Reach out to us at:
                </p>
                <a href="mailto:contact@trulyppc.com" 
                   className="text-base text-green-600 font-medium hover:text-green-700 
                            transition-colors duration-300">
                  contact@trulyppc.com
                </a>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Updated Bottom Section with Animation */}
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <div className="border-t-2 border-slate-200 pt-8">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <div className="text-base text-slate-700 tracking-wide">
                {new Date().getFullYear()} TrulyPPC. All rights reserved.
              </div>
              <div className="flex gap-8">
                <a href="/privacy-policy" className="text-base text-slate-700 hover:text-green-600 
                                   tracking-wide font-normal
                                   border-b border-transparent hover:border-green-600
                                   transition-all duration-300">
                  Privacy Policy
                </a>
                <a href="/terms-of-service" className="text-base text-slate-700 hover:text-green-600 
                                   tracking-wide font-normal
                                   border-b border-transparent hover:border-green-600
                                   transition-all duration-300">
                  Terms of Service
                </a>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </footer>
  );
}