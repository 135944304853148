import { motion, useAnimation } from "framer-motion";
import { faArrowRight, faRocket, faComments, faHeadset, faCheck, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

function NextStep() {
  const [scrollY, setScrollY] = useState(0);
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { 
        type: "spring", 
        stiffness: 100,
        damping: 15
      }
    }
  };

  const benefitItems = [
    { icon: faCheck, text: "Free initial assessment" },
    { icon: faCalendarCheck, text: "Quick onboarding process" },
    { icon: faHeadset, text: "Dedicated account manager" }
  ];

  return (
    <section id="next-step" className="relative py-48 bg-gradient-to-b from-slate-50 to-white overflow-hidden">
      {/* Enhanced background elements */}
      <div className="absolute inset-0">
        {/* Dynamic animated circles with parallax effect */}
        <div 
          className="absolute top-40 right-20 w-[400px] h-[400px] 
                border-[6px] border-dashed border-green-100/30 rounded-full"
          style={{ 
            transform: `rotate(${scrollY * 0.05}deg) translateY(${scrollY * 0.1}px)`,
            transition: 'transform 0.1s ease-out'
          }}
        ></div>
        
        <div 
          className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
                border-[4px] border-green-100/40 rounded-full 
                transform rotate-12"
          style={{ 
            transform: `rotate(${12 + scrollY * 0.02}deg) translateY(${-scrollY * 0.05}px)`,
            transition: 'transform 0.1s ease-out'
          }}
        ></div>

        {/* Floating elements */}
        <div className="absolute top-1/3 left-2/3 w-16 h-16 bg-green-50 rounded-full opacity-70
                     animate-[float_8s_ease-in-out_infinite]"
             style={{ animationDelay: '0s' }}></div>
             
        <div className="absolute top-2/3 left-1/4 w-8 h-8 bg-green-100 rounded-full opacity-60
                     animate-[float_6s_ease-in-out_infinite]"
             style={{ animationDelay: '1s' }}></div>
             
        <div className="absolute top-1/4 left-1/3 w-12 h-12 bg-green-50 rounded-full opacity-50
                     animate-[float_10s_ease-in-out_infinite]"
             style={{ animationDelay: '2s' }}></div>

        {/* Enhanced background pattern */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.02) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.02) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Enhanced decorative elements with parallax effect */}
        <svg 
          className="absolute top-20 left-20 w-40 h-40 text-green-100/30" 
          viewBox="0 0 100 100"
          style={{ 
            transform: `translateY(${scrollY * 0.08}px)`,
            transition: 'transform 0.1s ease-out'
          }}
        >
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
        
        {/* Added curved line */}
        <svg className="absolute bottom-40 right-20 w-60 h-60 text-green-100/30" viewBox="0 0 100 100">
          <path d="M10,80 C30,90 70,10 90,20" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_30s_linear_infinite_reverse]"/>
        </svg>

        {/* Abstract shapes */}
        <svg className="absolute bottom-20 left-1/2 -translate-x-1/2 w-96 h-20 text-green-100/20" viewBox="0 0 200 40">
          <defs>
            <linearGradient id="gradNext" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#059669" stopOpacity="0.05" />
              <stop offset="50%" stopColor="#059669" stopOpacity="0.2" />
              <stop offset="100%" stopColor="#059669" stopOpacity="0.05" />
            </linearGradient>
          </defs>
          <path d="M0,20 Q50,0 100,20 T200,20" 
                fill="none" stroke="url(#gradNext)" strokeWidth="4" strokeLinecap="round"
                className="animate-[dash_10s_linear_infinite_alternate]"/>
        </svg>
      </div>

      <div className="max-w-7xl mx-auto px-6 relative z-10">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={containerVariants}
          className="space-y-20"
        >
          <motion.div variants={itemVariants} className="text-center max-w-4xl mx-auto">
            {/* Enhanced badge */}
            <div className="inline-block mb-6">
              <span className="inline-flex items-center px-6 py-2.5 rounded-full text-sm font-medium font-poppins
                           bg-white border-2 border-green-100 
                           shadow-[4px_4px_0px_0px_rgba(5,150,105,0.15)]
                           hover:shadow-[6px_6px_0px_0px_rgba(5,150,105,0.2)]
                           transform hover:-translate-y-0.5 transition-all duration-300">
                <FontAwesomeIcon icon={faRocket} className="mr-2 text-green-600" />
                <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse mr-2"></span>
                Ready to Transform Your PPC?
              </span>
            </div>

            {/* Enhanced heading with animated underline */}
            <h2 className="text-5xl sm:text-6xl md:text-7xl font-extrabold mb-6 leading-tight tracking-tight text-slate-900 font-raleway">
              TAKE THE NEXT <span className="text-green-600 relative inline-flex">
                STEP
                <motion.svg 
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 1.5, ease: "easeInOut" }}
                  className="absolute -bottom-2 left-0 w-full" 
                  height="8" 
                  viewBox="0 0 100 8"
                >
                  <path d="M0,4 Q25,1 50,4 T100,4" fill="none" stroke="#059669" strokeWidth="3"/>
                </motion.svg>
              </span>
            </h2>
            
            <p className="text-lg md:text-xl text-slate-600 max-w-3xl mx-auto 
                        font-normal leading-relaxed font-montserrat">
              Whether you're scaling an existing product line or diving into a new category, we're here to help 
              your Amazon advertising make an impact. Partner with TrulyPPC now, and as we open up our 
              proprietary tool for your direct use, you'll be at the front of the line—ready to leverage it for 
              game-changing results.
            </p>
          </motion.div>

          <motion.div variants={itemVariants} className="flex flex-col items-center space-y-12">
            {/* Enhanced CTA card with gradient background */}
            <div className="w-full max-w-4xl bg-white rounded-3xl overflow-hidden 
                         border-2 border-green-100 shadow-[8px_8px_0px_0px_rgba(5,150,105,0.15)]
                         relative">
              {/* Background decorative elements */}
              <div className="absolute inset-0 overflow-hidden">
                <div className="absolute top-0 right-0 w-80 h-80 
                             bg-green-50 rounded-full -translate-y-40 translate-x-40"></div>
                <div className="absolute bottom-0 left-0 w-64 h-64 
                             bg-green-50 rounded-full translate-y-32 -translate-x-32"></div>
              </div>
              
              <div className="relative z-10 p-8 md:p-12">
                <div className="flex flex-col md:flex-row gap-8 md:gap-16 items-center md:items-start">
                  {/* Left side content */}
                  <div className="md:flex-1 space-y-6 text-center md:text-left">
                    <h3 className="text-2xl md:text-3xl font-bold text-slate-900 font-raleway">
                      Start with a <span className="text-green-600">Free PPC Audit</span>
                    </h3>
                    <p className="text-slate-600 font-montserrat">
                      Discover untapped opportunities in your Amazon PPC campaigns with our comprehensive audit. 
                      We'll analyze your current performance and provide actionable recommendations.
                    </p>
                    
                    {/* Benefit list */}
                    <div className="space-y-3">
                      {benefitItems.map((item, index) => (
                        <div key={index} className="flex items-center">
                          <div className="flex-shrink-0 w-8 h-8 bg-green-100 rounded-full 
                                       flex items-center justify-center">
                            <FontAwesomeIcon icon={item.icon} className="h-4 w-4 text-green-600" />
                          </div>
                          <span className="ml-3 text-slate-700 font-montserrat">{item.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  
                  {/* Right side CTA buttons */}
                  <div className="md:flex-1 flex flex-col gap-5 w-full">
                    {/* Primary CTA */}
                    <Link to="/audit" className="block">
                      <motion.button
                        whileHover={{ scale: 1.03 }}
                        whileTap={{ scale: 0.98 }}
                        className="w-full group relative overflow-hidden px-10 py-5 rounded-xl
                                bg-gradient-to-r from-green-600 to-green-500
                                text-white font-semibold text-lg font-poppins
                                shadow-[5px_5px_0px_0px_rgba(5,150,105,0.3)]
                                hover:shadow-[7px_7px_0px_0px_rgba(5,150,105,0.35)]
                                transform hover:-translate-y-0.5 transition-all duration-300"
                      >
                        <span className="relative z-10 flex items-center justify-center">
                          Get Your Free PPC Audit
                          <FontAwesomeIcon icon={faArrowRight} 
                            className="ml-2 transition-transform duration-300 group-hover:translate-x-2" 
                          />
                        </span>
                        <div className="absolute inset-0 bg-gradient-to-r from-green-500 to-green-600 transform scale-x-0 group-hover:scale-x-100 origin-left transition-transform duration-500"></div>
                      </motion.button>
                    </Link>
                    
                    {/* Secondary CTA */}
                    <Link to="/chat" className="block">
                      <motion.button
                        whileHover={{ scale: 1.03 }}
                        whileTap={{ scale: 0.98 }}
                        className="w-full group relative px-10 py-5 rounded-xl
                                bg-white border-2 border-green-100
                                hover:border-green-200 text-slate-900 
                                font-semibold text-lg font-poppins
                                shadow-[4px_4px_0px_0px_rgba(5,150,105,0.1)]
                                hover:shadow-[6px_6px_0px_0px_rgba(5,150,105,0.15)]
                                transform hover:-translate-y-0.5
                                transition-all duration-300"
                      >
                        <span className="flex items-center justify-center">
                          <FontAwesomeIcon icon={faComments} className="mr-2 group-hover:animate-bounce" />
                          Schedule a strategy call
                        </span>
                      </motion.button>
                    </Link>
                    
                    {/* Enhanced Trust Badge */}
                    <div className="text-base text-slate-600 flex items-center justify-center gap-3 
                                  mt-3 font-normal tracking-wide font-montserrat">
                      <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                      </svg>
                      100% Secure and Confidential
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Added testimonial for social proof */}
            <motion.div 
              variants={itemVariants}
              className="max-w-3xl mx-auto bg-white p-8 md:p-10 rounded-2xl 
                       border-2 border-green-100 
                       shadow-[5px_5px_0px_0px_rgba(5,150,105,0.1)]
                       relative"
            >
              <div className="absolute top-6 left-6 text-green-100 text-6xl font-serif">"</div>
              <div className="relative z-10">
                <p className="text-slate-700 text-lg italic mb-6 px-8 font-montserrat">
                  TrulyPPC has transformed our Amazon advertising strategy. Their data-driven approach and 
                  proprietary tools have helped us reduce wasted ad spend by 42% while increasing our 
                  sales by over 70%. The ROI speaks for itself.
                </p>
                <div className="flex items-center">
                  <div className="w-12 h-12 bg-green-600 rounded-full flex items-center justify-center text-white font-bold text-xl font-poppins">
                    JD
                  </div>
                  <div className="ml-4">
                    <div className="font-semibold text-slate-900 font-montserrat">Jessica Dawson</div>
                    <div className="text-sm text-slate-600 font-montserrat">E-commerce Director, HomeStyle Products</div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}

export default NextStep;