import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

export default function Hero() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const iconVariants = {
    initial: { scale: 0.8, opacity: 0 },
    animate: { 
      scale: 1, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        delay: 1.2
      }
    }
  };

  const statsData = [
    { value: "45%", label: "Avg. ACOS Improvement" },
    { value: "2.7x", label: "Avg. ROAS Growth" },
    { value: "78%", label: "Client Retention Rate" }
  ];

  const scrollToNextStep = (e) => {
    e.preventDefault();
    const nextStepSection = document.getElementById('next-step');
    nextStepSection.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToHowItWorks = (e) => {
    e.preventDefault();
    const howItWorksSection = document.getElementById('how-it-works');
    howItWorksSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className="relative bg-gradient-to-b from-slate-50 to-white text-slate-800 pt-40 pb-40 overflow-hidden">
      {/* Enhanced background elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Improved background pattern */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.02) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.02) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Parallax moving elements */}
        <div 
          className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-green-100/30 rounded-full"
          style={{ 
            transform: `rotate(${scrollY * 0.05}deg) translateY(${scrollY * 0.1}px)`,
            transition: 'transform 0.1s ease-out'
          }}
        ></div>
        
        <div 
          className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-green-100/40 rounded-full 
             transform rotate-12"
          style={{ 
            transform: `rotate(${12 + scrollY * 0.02}deg) translateY(${-scrollY * 0.05}px)`,
            transition: 'transform 0.1s ease-out'
          }}
        ></div>

        {/* Enhanced decorative elements */}
        <svg 
          className="absolute top-20 left-20 w-40 h-40 text-green-100/30" 
          viewBox="0 0 100 100"
          style={{ 
            transform: `translateY(${scrollY * 0.08}px)`,
            transition: 'transform 0.1s ease-out'
          }}
        >
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>

        {/* Added floating elements */}
        <div className="absolute top-1/3 left-2/3 w-16 h-16 bg-green-50 rounded-full opacity-70
                      animate-[float_8s_ease-in-out_infinite]"
             style={{ animationDelay: '0s' }}></div>
        <div className="absolute top-2/3 left-1/4 w-8 h-8 bg-green-100 rounded-full opacity-60
                      animate-[float_6s_ease-in-out_infinite]"
             style={{ animationDelay: '1s' }}></div>
        <div className="absolute top-1/4 left-1/3 w-12 h-12 bg-green-50 rounded-full opacity-50
                      animate-[float_10s_ease-in-out_infinite]"
             style={{ animationDelay: '2s' }}></div>

        {/* Abstract shapes */}
        <svg className="absolute bottom-20 right-20 w-60 h-60 text-green-100/20" viewBox="0 0 100 100">
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#059669" stopOpacity="0.1" />
              <stop offset="100%" stopColor="#059669" stopOpacity="0.2" />
            </linearGradient>
          </defs>
          <path d="M30,20 Q60,5 80,30 T90,80 T30,90 T10,50 T30,20" 
                fill="url(#grad1)" stroke="none"
                className="animate-[morph_15s_ease-in-out_infinite_alternate]"/>
        </svg>
      </div>
      
      <motion.div 
        className="max-w-7xl mx-auto px-6 relative z-10"
        initial="hidden" 
        animate="visible" 
        variants={containerVariants}
      >
        <div className="grid md:grid-cols-12 gap-12 items-center">
          {/* Left Content Column */}
          <div className="md:col-span-7 text-left md:pr-8">
            {/* Updated badge styling */}
            <motion.div variants={itemVariants} className="inline-block mb-6">
              <span className="inline-flex items-center px-6 py-2.5 rounded-full text-sm font-medium 
                            bg-white border-2 border-green-100 
                            shadow-[4px_4px_0px_0px_rgba(5,150,105,0.15)]
                            hover:shadow-[6px_6px_0px_0px_rgba(5,150,105,0.2)]
                            transform hover:-translate-y-0.5 transition-all duration-300">
                <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse mr-2"></span>
                Next-Generation Amazon PPC Management
              </span>
            </motion.div>

            {/* Enhanced heading with better typography */}
            <motion.h1 
              variants={itemVariants}
              className="text-5xl sm:text-6xl xl:text-7xl font-extrabold mb-6 leading-[1.1] tracking-tight"
            >
              <span className="block">Data-Driven</span>
              <span className="inline-flex items-baseline">
                <span className="text-slate-900">
                  Amazon
                </span>
                <span className="text-green-600 ml-3 relative">
                  Growth
                  <svg className="absolute -bottom-2 left-0 w-full" height="8" viewBox="0 0 300 8">
                    <path d="M0,4 Q75,1 150,4 T300,4" fill="none" stroke="#059669" strokeWidth="3"
                          className="animate-[dash_3s_linear_infinite]"/>
                  </svg>
                </span>
              </span>
              <span className="block mt-1">
                <span className="text-slate-900">with</span>
                <span className="text-green-600 font-extrabold ml-3 relative">
                  TrulyPPC
                  <motion.svg 
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 1.5, ease: "easeInOut", delay: 0.8 }}
                    className="absolute -bottom-2 left-0 w-full" 
                    height="8" 
                    viewBox="0 0 300 8"
                  >
                    <path d="M0,4 Q75,1 150,4 T300,4" fill="none" stroke="#059669" strokeWidth="3"/>
                  </motion.svg>
                </span>
              </span>
            </motion.h1>

            <motion.p 
              variants={itemVariants}
              className="text-lg text-slate-700 max-w-xl mb-6
                       leading-relaxed font-normal"
            >
              <span className="font-medium">Your Amazon PPC deserves better than generic agencies.</span> We combine human expertise with proprietary AI tools to deliver measurable, sustainable results that grow your business.
            </motion.p>

            <motion.p 
              variants={itemVariants}
              className="text-md text-slate-600 max-w-xl mb-8
                       leading-relaxed"
            >
              Our specialized PPC optimization platform used by top sellers will soon be available for direct access. Partner with us for data-driven success on the world's largest marketplace.
            </motion.p>

            {/* Enhanced statistics section */}
            <motion.div 
              variants={itemVariants}
              className="grid grid-cols-3 gap-4 mb-10 max-w-xl"
            >
              {statsData.map((stat, index) => (
                <div key={index} className="text-left">
                  <div className="text-2xl sm:text-3xl font-bold text-green-600">{stat.value}</div>
                  <div className="text-xs sm:text-sm text-slate-600 font-medium">{stat.label}</div>
                </div>
              ))}
            </motion.div>

            {/* Enhanced CTA buttons */}
            <motion.div 
              variants={itemVariants}
              className="flex flex-col sm:flex-row gap-5 mb-10"
            >
              <a href="#next-step" 
                onClick={scrollToNextStep}
                className="group relative overflow-hidden px-8 py-4 rounded-xl font-semibold
                          bg-green-600 text-white
                          shadow-[5px_5px_0px_0px_rgba(5,150,105,0.3)]
                          hover:shadow-[7px_7px_0px_0px_rgba(5,150,105,0.35)]
                          transform hover:-translate-y-0.5 transition-all duration-300"
              >
                <span className="relative z-10 flex items-center justify-center">
                  Get Started 
                  <svg className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-green-500 transform scale-x-0 group-hover:scale-x-100 origin-left transition-transform duration-500"></div>
              </a>
              
              <a href="#how-it-works" 
                onClick={scrollToHowItWorks}
                className="group relative overflow-hidden bg-white
                          border-2 border-slate-200
                          px-8 py-4 rounded-xl font-semibold text-slate-900
                          shadow-[4px_4px_0px_0px_rgba(0,0,0,0.08)]
                          hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.1)]
                          hover:border-green-200
                          transform hover:-translate-y-0.5 transition-all duration-300"
              >
                <span className="relative z-10 flex items-center justify-center">
                  See Our Process
                  <svg className="ml-2 w-5 h-5 text-slate-500 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </span>
              </a>
            </motion.div>

            {/* Enhanced trust indicators */}
            <motion.div
              variants={itemVariants}
              className="flex items-center text-sm text-slate-500"
            >
              <div className="flex -space-x-2 mr-3">
                <div className="w-7 h-7 rounded-full bg-green-100 flex items-center justify-center text-green-600 font-bold">A</div>
                <div className="w-7 h-7 rounded-full bg-green-50 flex items-center justify-center text-green-600 font-bold">B</div>
                <div className="w-7 h-7 rounded-full bg-green-100 flex items-center justify-center text-green-600 font-bold">C</div>
              </div>
              <span>Trusted by 200+ Amazon Sellers</span>
            </motion.div>
          </div>

          {/* Right Image/Badge Column */}
          <div className="md:col-span-5 flex justify-center items-center">
            <motion.div 
              initial="initial"
              animate="animate"
              variants={iconVariants}
              className="relative"
            >
              {/* Background glows */}
              <div className="absolute inset-0 bg-gradient-to-br from-green-100/50 to-transparent blur-3xl rounded-full transform scale-150 opacity-70 animate-[pulse_4s_ease-in-out_infinite]"></div>
              
              {/* Badge container with enhanced effects */}
              <div className="relative z-10 flex flex-col items-center">
                {/* Top decorative element */}
                <motion.div 
                  animate={{ 
                    y: [0, -8, 0],
                    rotateZ: [0, 2, 0, -2, 0]
                  }}
                  transition={{ 
                    duration: 5, 
                    ease: "easeInOut", 
                    repeat: Infinity,
                  }}
                  className="w-16 h-16 bg-white rounded-full shadow-lg flex items-center justify-center mb-4 border-4 border-green-100"
                >
                  <svg className="w-8 h-8 text-green-600" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>
                  </svg>
                </motion.div>
                
                {/* Main badge with animation */}
                <motion.div
                  animate={{ 
                    scale: [1, 1.02, 1],
                    rotate: [0, 1, 0, -1, 0]
                  }}
                  transition={{ 
                    duration: 8, 
                    ease: "easeInOut", 
                    repeat: Infinity,
                  }}
                  className="relative"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-green-400/20 to-green-600/10 blur-xl rounded-3xl transform scale-110"></div>
                  <img 
                    src="/Verifiedpartnerbadge.png"
                    alt="Amazon Ads Verified Partner"
                    className="h-56 sm:h-64 relative filter drop-shadow-xl"
                  />
                </motion.div>
                
                {/* Bottom decorative elements */}
                <div className="mt-6 flex items-center space-x-4">
                  <motion.div 
                    animate={{ 
                      scale: [1, 1.05, 1],
                      rotate: [0, 5, 0],
                    }}
                    transition={{ 
                      duration: 3, 
                      ease: "easeInOut", 
                      repeat: Infinity,
                      repeatType: "reverse",
                      delay: 0.5
                    }}
                    className="w-12 h-12 bg-green-50 rounded-xl shadow-md flex items-center justify-center border-2 border-green-100"
                  >
                    <svg className="w-6 h-6 text-green-600" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20.283 10.356h-8.327v3.451h4.792c-.446 2.193-2.313 3.453-4.792 3.453a5.27 5.27 0 0 1-5.279-5.28 5.27 5.27 0 0 1 5.279-5.279c1.259 0 2.397.447 3.29 1.178l2.6-2.599c-1.584-1.381-3.615-2.233-5.89-2.233a8.908 8.908 0 0 0-8.934 8.934 8.907 8.907 0 0 0 8.934 8.934c4.467 0 8.529-3.249 8.529-8.934 0-.528-.081-1.097-.202-1.625z"/>
                    </svg>
                  </motion.div>
                  <motion.div 
                    animate={{ 
                      scale: [1, 1.05, 1],
                      rotate: [0, -5, 0],
                    }}
                    transition={{ 
                      duration: 4, 
                      ease: "easeInOut", 
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                    className="w-12 h-12 bg-white rounded-xl shadow-md flex items-center justify-center border-2 border-green-100"
                  >
                    <svg className="w-6 h-6 text-green-600" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
                    </svg>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </header>
  );
}