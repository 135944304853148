import React from 'react';
import { motion } from "framer-motion";
import { FaRobot, FaUsers, FaChartLine, FaCog } from "react-icons/fa";

function Technology() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: "easeOut" }
  };

  const features = [
    {
      icon: <FaChartLine className="w-8 h-8" />,
      title: "Real-time Analytics",
      description: "Instant insights and trend detection",
      gradient: "from-green-500 to-green-600"
    },
    {
      icon: <FaRobot className="w-8 h-8" />,
      title: "AI-Powered",
      description: "Smart automation and decision making",
      gradient: "from-green-600 to-green-700"
    },
    {
      icon: <FaCog className="w-8 h-8" />,
      title: "Customizable",
      description: "Tailored to your specific needs",
      gradient: "from-green-500 to-green-600"
    }
  ];

  return (
    <section className="relative py-40 bg-slate-50 overflow-hidden">
      {/* Sketch-style Background */}
      <div className="absolute inset-0">
        {/* Hand-drawn circles and decorative elements */}
        <div className="absolute top-40 right-20 w-[400px] h-[400px] 
             border-[6px] border-dashed border-green-100/30 rounded-full 
             animate-[spin_30s_linear_infinite]"></div>
        
        <div className="absolute -bottom-20 -left-20 w-[300px] h-[300px] 
             border-[4px] border-green-100/40 rounded-full 
             transform rotate-12"></div>

        {/* Sketch-style dot pattern */}
        <div className="absolute inset-0" style={{
          backgroundImage: `
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px),
            radial-gradient(circle, rgba(0,0,0,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px',
          backgroundPosition: '0 0, 20px 20px'
        }}></div>

        {/* Squiggly decorative lines */}
        <svg className="absolute top-20 left-20 w-40 h-40 text-green-100/30" viewBox="0 0 100 100">
          <path d="M10,50 Q25,25 40,50 T70,50 T100,50" 
                fill="none" stroke="currentColor" strokeWidth="2"
                className="animate-[dash_20s_linear_infinite]"/>
        </svg>
      </div>

      <div className="max-w-7xl mx-auto px-6 space-y-32 relative z-10">
        <motion.div initial="initial" whileInView="animate" viewport={{ once: true }} className="space-y-20">
          <motion.div {...fadeInUp} className="text-center space-y-8">
            <div className="inline-block mb-4">
              <span className="inline-flex items-center px-8 py-3 rounded-full text-sm font-medium 
                           bg-white border-2 border-slate-200
                           shadow-[4px_4px_0px_0px_rgba(0,0,0,0.1)]
                           hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,0.12)]
                           transform hover:-translate-y-0.5 transition-all duration-300">
                <span className="mr-3">🚀</span>
                Technology Stack
              </span>
            </div>

            <h2 className="text-6xl md:text-7xl font-bold tracking-tight text-slate-900 relative">
              OUR TECH, YOUR FUTURE <span className="text-green-600">ADVANTAGE</span>
              <svg className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-40" height="10" viewBox="0 0 200 10">
                <path d="M0,5 Q50,2 100,5 T200,5" fill="none" stroke="#059669" strokeWidth="2"
                      className="animate-[dash_3s_linear_infinite]"/>
              </svg>
            </h2>
            
            <p className="text-lg md:text-xl text-slate-700 max-w-3xl mx-auto 
                          leading-relaxed font-normal tracking-wide">
              We rely on top industry tools alongside our own proprietary platform to dig deeper into the data. 
              This lets us make adjustments on the fly, catch trends early, and uncover hidden opportunities 
              for growth.
            </p>
          </motion.div>

          {/* Enhanced feature cards */}
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                {...fadeInUp}
                transition={{ delay: index * 0.2 }}
                className="group relative bg-white p-10 
                          rounded-3xl overflow-hidden
                          border-2 border-slate-200
                          hover:border-slate-300
                          shadow-[6px_6px_0px_0px_rgba(0,0,0,0.05)]
                          hover:shadow-[10px_10px_0px_0px_rgba(0,0,0,0.08)]
                          transform hover:-translate-y-1
                          transition-all duration-500"
              >
                {/* Decorative elements */}
                <div className="absolute top-0 right-0 w-40 h-40 
                             border border-dashed border-green-100 
                             rounded-full -translate-y-20 translate-x-20 
                             group-hover:scale-150 transition-transform duration-500"></div>

                <div className="relative">
                  <div className={`bg-gradient-to-br ${feature.gradient} p-4 rounded-xl 
                                w-16 h-16 flex items-center justify-center mb-8 
                                transform rotate-3 
                                shadow-[4px_4px_0px_0px_rgba(5,150,105,0.3)]
                                group-hover:shadow-[6px_6px_0px_0px_rgba(5,150,105,0.35)]
                                group-hover:rotate-6 transition-all duration-300`}>
                    {React.cloneElement(feature.icon, { 
                      className: "w-8 h-8 text-white transform -rotate-3 group-hover:-rotate-6 transition-transform duration-300" 
                    })}
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-slate-900
                             group-hover:text-green-600 transition-colors duration-300">
                    {feature.title}
                  </h3>
                  <p className="text-base md:text-lg text-slate-700 leading-relaxed 
                                tracking-wide font-normal">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Enhanced Behind the Scenes Section */}
        <motion.div initial="initial" whileInView="animate" viewport={{ once: true }} className="relative">
          <motion.div {...fadeInUp} className="space-y-12">
            <div className="flex flex-col items-center justify-center space-y-8">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-br from-green-500 to-green-600 blur-lg opacity-50 
                             animate-pulse"></div>
                <div className="relative bg-gradient-to-br from-green-500 to-green-600 p-6 rounded-2xl 
                            transform rotate-3
                            shadow-[6px_6px_0px_0px_rgba(5,150,105,0.3)]
                            hover:shadow-[8px_8px_0px_0px_rgba(5,150,105,0.35)]
                            hover:rotate-6 transition-all duration-300">
                  <FaUsers className="w-12 h-12 text-white transform -rotate-3 
                                  hover:-rotate-6 transition-transform duration-300" />
                </div>
              </div>
              <h2 className="text-5xl md:text-6xl font-bold tracking-tight text-slate-900 text-center relative">
                WHO'S BEHIND THE <span className="text-green-600">SCENES</span>?
                <svg className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-40" height="10" viewBox="0 0 200 10">
                  <path d="M0,5 Q50,2 100,5 T200,5" fill="none" stroke="#059669" strokeWidth="2"
                        className="animate-[dash_3s_linear_infinite]"/>
                </svg>
              </h2>
            </div>
            
            <div className="relative bg-white p-12 rounded-3xl
                          border-2 border-slate-200
                          hover:border-slate-300
                          shadow-[6px_6px_0px_0px_rgba(0,0,0,0.05)]
                          hover:shadow-[10px_10px_0px_0px_rgba(0,0,0,0.08)]
                          transform hover:-translate-y-1
                          transition-all duration-500">
              {/* Decorative corner elements */}
              <div className="absolute top-0 right-0 w-40 h-40 
                           border border-dashed border-green-100 
                           rounded-full -translate-y-20 translate-x-20"></div>
              <div className="absolute bottom-0 left-0 w-32 h-32 
                           border border-dashed border-green-100 
                           rounded-full translate-y-16 -translate-x-16"></div>
              
              <p className="text-lg md:text-xl text-slate-700 leading-relaxed 
                            tracking-wide font-normal relative">
                At the heart of TrulyPPC is a small but mighty crew of PPC pros who love what they do. 
                We stay plugged into Amazon's updates, test new ad features early on, and share our 
                findings with you. When our internal platform becomes available to you, it'll be like 
                joining our inner circle—tapping into the same insights, data, and tools we use every day.
              </p>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}

export default Technology;